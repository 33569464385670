<template>
  <section class="bg-darker">
    <!-- featured slider -->
    <slider
      v-if="featuredCategory"
      :items="featuredCategory.programs"
      class="mb-sm"
      :slide-styles="{
        'max-height': '450px',
      }"
    >
      <template v-slot="{ item: program }">
        <router-link
          :to="{
            name: 'media-detail',
            params: { slug: program.slug },
          }"
          class="d-block w-100 h-100"
        >
          <img
            :src="
              program.img_featured
                ? program.img_featured + '?width=1600'
                : require('@/assets/img/poster_placeholder.jpg')
            "
            class="w-100 h-100 d-none d-md-block"
            :style="{
              objectFit: 'cover',
              objectPosition: 'center',
            }"
            alt="Featured Program"
          />
          <img
            :src="
              program.img_avatar
                ? program.img_avatar + '?width=420'
                : require('@/assets/img/poster_placeholder.jpg')
            "
            class="squareimage d-md-none"
            :style="{
              objectFit: 'cover',
              objectPosition: 'center',
            }"
            alt="Featured Program"
          />
        </router-link>
      </template>
    </slider>

    <profiles
      v-if="presentersSorted.length > 1"
      :presenters="presentersSorted"
      class="mb-sm"
    >
    </profiles>

    <div v-if="categories.length" class="mx-3 ml-sm-5 ml-md-6 ml-lg-7 mr-sm-0">
      <div v-for="(category, index) in regularCategories" :key="index">
        <span v-if="category.programs.length">
          <h2
            class="text-2xl text-primary font-weight-600 text-capitalize ml-sm-0 mb-2"
          >
            <router-link
              :to="{
                name: 'category-detail',
                params: { id: category.id },
              }"
            >
              {{ category.name }}
            </router-link>
            <router-link
              :to="{
                name: 'category-detail',
                params: { id: category.id },
              }"
              class="cursor-pointer small"
            >
              <span
                class="btn btn-sm btn-icon text-white float-right font-weight-light mr-sm-5 mr-md-6 mr-lg-7"
              >
                View all <i class="ni ni-bold-right"></i>
              </span>
            </router-link>
          </h2>

          <programs-slider
            :programs="category.programs"
            class="mb-sm"
          ></programs-slider>
        </span>
      </div>
    </div>

    <div v-else class="h-100 d-flex align-items-center justify-content-center">
      <span class="text-lg font-weight-900 text-white">Loading...</span>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import Category from "@/api/Category";
import Slider from "@/components/Slider";
import Profiles from "@/components/Profiles";
import ProgramsSlider from "@/components/ProgramsSlider";

export default {
  name: "main-page",

  components: { Slider, ProgramsSlider, Profiles },

  async created() {
    this.$store.dispatch("fetchCategories");
    this.$store.dispatch("fetchPresenters");
  },

  computed: {
    ...mapState(["categories", "presenters"]),
    presentersSorted() {
      if (!this.presenters) {
        return [];
      }
      let p = this.presenters;

      p = p.filter((p) => {
        return p.programs.length > 0 &&
          typeof p.featured !== "undefined" &&
          p.featured
          ? p
          : null;
      });

      return p.sort(function (a, b) {
        return a.full_name === b.full_name
          ? 0
          : a.full_name < b.full_name
          ? -1
          : 1;
      });
    },
    featuredCategory() {
      return this.categories.find((c) => c.code === Category.featured);
    },

    regularCategories() {
      return this.categories.filter((c) => c.code !== Category.featured);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  background-image: url("~@/assets/img/main_bg.png");
  background-size: cover;
}

.squareimage {
  width: 100vw;
  height: 100vw;
}
</style>
