<template>
  <swiper ref="slider" :options="sliderOptions" :auto-update="false">
    <swiper-slide
      v-for="(item, index) in items"
      :key="index"
      :class="sliderClasses"
      :style="slideStyles"
    >
      <slot :item="item"></slot>
    </swiper-slide>

    <div
      ref="sliderNavPrev"
      class="swiper-button-prev text-white"
      slot="button-prev"
    ></div>
    <div
      ref="sliderNavNext"
      class="swiper-button-next text-white"
      slot="button-next"
    ></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "slider",

  components: { Swiper, SwiperSlide },

  props: {
    items: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },

    sliderClasses: {
      type: String,
      default: "",
    },

    slideStyles: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    sliderOptions() {
      return {
        navigation: {
          prevEl: `.swiper-button-prev`,
          nextEl: `.swiper-button-next`,
        },
        ...this.options,
      };
    },
  },
};
</script>
