<template>
  <slider :items="presenters" :options="sliderOptions">
    <template v-slot="{ item: sensei }">
      <div class="mx-auto">
        <router-link
          :to="`/profile/${sensei.id}`"
          v-lazy-container
          v-bind:key="sensei.id"
        >
          <div
            class="d-block mb-4 bg-black border-none shadow with-shadow rounded-circle"
          >
            <img
              :data-src="
                sensei.img_poster
                  ? sensei.img_poster + '?width=320'
                  : emptyImage
              "
              :data-loading="emptyImage"
              :data-error="emptyImage"
              class="img-fluid rounded-circle"
            />
          </div>
          <h4 class="w-75 text-primary small bold text-center small m-auto ">
            {{ sensei.full_name }}
          </h4>
        </router-link>
      </div>
    </template>
  </slider>
</template>

<script>
import merge from "lodash/merge";
import Slider from "@/components/Slider";

export default {
  name: "profiles",

  components: { Slider },

  props: {
    presenters: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      emptyImage:
        "https://stream.bujin.tv/tenant0851fd69-1b37-4530-a80c-3676817e9472/people/tests/photo_person_800x800.jpg",
      baseOptions: {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 20,

        breakpoints: {
          320: {
            slidesPerView: 2.5,
            centeredSlides: false,
          },
          576: {
            slidesPerView: 2.5,
            centeredSlides: false,
          },

          768: {
            slidesPerView: 6.5,
            centeredSlides: false,
          },

          992: {
            slidesPerView: 7.5,
            centeredSlides: false,
          },

          1024: {
            slidesPerView: 8.5,
            centeredSlides: false,
          },

          1201: {
            slidesPerView: 12.5,
            centeredSlides: false,
          },
        },

        navigation: {
          prevEl: `.swiper-button-prev`,
          nextEl: `.swiper-button-next`,
        },
      },
    };
  },
  computed: {
    sliderOptions() {
      return merge(this.baseOptions, this.options);
    },
  },
};
</script>

<style lang="scss">
// .sensei-slide {
//   width: 92.1%;

//   @media (min-width: 576px) {
//     width: 235px;
//   }

//   @media (min-width: 768px) {
//     width: 215px;
//   }

//   @media (min-width: 1200px) {
//     width: 290px;
//   }
// }
</style>
