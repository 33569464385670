var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"bg-darker"},[(_vm.featuredCategory)?_c('slider',{staticClass:"mb-sm",attrs:{"items":_vm.featuredCategory.programs,"slide-styles":{
      'max-height': '450px',
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var program = ref.item;
return [_c('router-link',{staticClass:"d-block w-100 h-100",attrs:{"to":{
          name: 'media-detail',
          params: { slug: program.slug },
        }}},[_c('img',{staticClass:"w-100 h-100 d-none d-md-block",style:({
            objectFit: 'cover',
            objectPosition: 'center',
          }),attrs:{"src":program.img_featured
              ? program.img_featured + '?width=1600'
              : require('@/assets/img/poster_placeholder.jpg'),"alt":"Featured Program"}}),_c('img',{staticClass:"squareimage d-md-none",style:({
            objectFit: 'cover',
            objectPosition: 'center',
          }),attrs:{"src":program.img_avatar
              ? program.img_avatar + '?width=420'
              : require('@/assets/img/poster_placeholder.jpg'),"alt":"Featured Program"}})])]}}],null,false,1013482971)}):_vm._e(),(_vm.presentersSorted.length > 1)?_c('profiles',{staticClass:"mb-sm",attrs:{"presenters":_vm.presentersSorted}}):_vm._e(),(_vm.categories.length)?_c('div',{staticClass:"mx-3 ml-sm-5 ml-md-6 ml-lg-7 mr-sm-0"},_vm._l((_vm.regularCategories),function(category,index){return _c('div',{key:index},[(category.programs.length)?_c('span',[_c('h2',{staticClass:"text-2xl text-primary font-weight-600 text-capitalize ml-sm-0 mb-2"},[_c('router-link',{attrs:{"to":{
              name: 'category-detail',
              params: { id: category.id },
            }}},[_vm._v(" "+_vm._s(category.name)+" ")]),_c('router-link',{staticClass:"cursor-pointer small",attrs:{"to":{
              name: 'category-detail',
              params: { id: category.id },
            }}},[_c('span',{staticClass:"btn btn-sm btn-icon text-white float-right font-weight-light mr-sm-5 mr-md-6 mr-lg-7"},[_vm._v(" View all "),_c('i',{staticClass:"ni ni-bold-right"})])])],1),_c('programs-slider',{staticClass:"mb-sm",attrs:{"programs":category.programs}})],1):_vm._e()])}),0):_c('div',{staticClass:"h-100 d-flex align-items-center justify-content-center"},[_c('span',{staticClass:"text-lg font-weight-900 text-white"},[_vm._v("Loading...")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }