var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slider',{attrs:{"items":_vm.programs,"options":_vm.sliderOptions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var program = ref.item;
return [_c('div',{staticClass:"mx-auto"},[_c('router-link',{directives:[{name:"lazy-container",rawName:"v-lazy-container"}],key:program.id,staticClass:"d-block mb-4 bg-dark",attrs:{"to":{
          name: 'media-detail',
          params: { slug: program.slug },
        }}},[_c('img',{staticClass:"img-fluid rounded",attrs:{"data-src":program.img_poster
              ? program.img_poster + '?width=320'
              : require('@/assets/img/poster_placeholder.jpg'),"data-loading":require('@/assets/img/poster_placeholder.jpg'),"data-error":require('@/assets/img/poster_placeholder.jpg'),"alt":program.title}})]),_c('router-link',{attrs:{"to":{
          name: 'media-detail',
          params: { slug: program.slug },
        }}},[_c('h3',{staticClass:"text-md font-weight-700 text-white py-1"},[_vm._v(" "+_vm._s(program.title)+" ")])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }