<template>
  <slider :items="programs" :options="sliderOptions">
    <template v-slot="{ item: program }">
      <div class="mx-auto">
        <router-link
          :to="{
            name: 'media-detail',
            params: { slug: program.slug },
          }"
          v-lazy-container
          class="d-block mb-4 bg-dark"
          v-bind:key="program.id"
        >
          <img
            :data-src="
              program.img_poster
                ? program.img_poster + '?width=320'
                : require('@/assets/img/poster_placeholder.jpg')
            "
            :data-loading="require('@/assets/img/poster_placeholder.jpg')"
            :data-error="require('@/assets/img/poster_placeholder.jpg')"
            class="img-fluid rounded"
            :alt="program.title"
          />
        </router-link>
        <router-link
          :to="{
            name: 'media-detail',
            params: { slug: program.slug },
          }"
        >
          <h3 class="text-md font-weight-700 text-white py-1">
            {{ program.title }}
          </h3>
        </router-link>
      </div>
    </template>
  </slider>
</template>

<script>
import merge from "lodash/merge";
import Slider from "@/components/Slider";

export default {
  name: "programs-slider",

  components: { Slider },

  props: {
    programs: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      baseOptions: {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 20,

        breakpoints: {
          240: {
            slidesPerView: 1.5,
            centeredSlides: false,
          },
          576: {
            slidesPerView: 2.5,
            centeredSlides: false,
          },

          768: {
            slidesPerView: 3.5,
            centeredSlides: false,
          },

          992: {
            slidesPerView: 4.5,
            centeredSlides: false,
          },

          1200: {
            slidesPerView: 5.5,
            centeredSlides: false,
          },
        },

        navigation: {
          prevEl: `.swiper-button-prev`,
          nextEl: `.swiper-button-next`,
        },
      },
    };
  },
  computed: {
    sliderOptions() {
      return merge(this.baseOptions, this.options);
    },
  },
};
</script>

<style lang="scss">
// .program-slide {
//   width: 92.1%;

//   @media (min-width: 576px) {
//     width: 235px;
//   }

//   @media (min-width: 768px) {
//     width: 215px;
//   }

//   @media (min-width: 1200px) {
//     width: 290px;
//   }
// }
</style>
